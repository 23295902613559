import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiUrl, uniqueID } from '../../../utils/constants';
import axios from 'axios';
import { setOutlet, setOutletBrand } from '../../../store/reducers/outlet';
import useLayout from '../../Layout/useLayout';
import { useHistory } from 'react-router-dom';

const useOutlet = () => {
	const dispatch = useDispatch();
  const history = useHistory();

	const { isNearBottom } = useLayout();

	const outlets = useSelector((state) => state.outlet?.list || []);
	const outletBrand = useSelector((state) => state.outlet?.outletBrand || {});

	const loading = useSelector((state) => state.outlet?.loading || false);
	const latitude = useSelector((state) => state.user?.latitude);
	const longitude = useSelector((state) => state.user?.longitude);

	const [isOpenOtherLocation, setIsOpenOtherLocation] = useState(false);
	const [outletByLocation, setOutletByLocation] = useState([]);
	const [brandID, setBrandID] = useState(null);
	const [isLoadingOtherLocation, setisLoadingOtherLocation] = useState(false);
  const [selectedStoreID, setSelectedStoreID] = useState(null)

	const [totalPage, setTotalPage] = useState(0);
	const [total, setTotal] = useState(1);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [isLoadMore, setIsLoadMore] = useState(false);

	useEffect(() => {
		if (!brandID || !outletBrand?.[brandID]?.length || !isOpenOtherLocation) return;
		setOutletByLocation(outletBrand[brandID]);
	}, [brandID, outletBrand, isOpenOtherLocation]);

	useEffect(() => {
		if (isOpenOtherLocation) return;
		setOutletByLocation([]);
    setSelectedStoreID(null);
	}, [isOpenOtherLocation]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        setIsOpenOtherLocation(false);
        return false;
      }
      return true;
    });
    return () => {
      unblock();
    };
  }, [isOpenOtherLocation, history]);

	const handleOutletByLocation = async (item = null) => {
		console.log('handleOutletByLocation');
    setisLoadingOtherLocation(true);
		setBrandID(item?.brandID);
    setSelectedStoreID(item?.storeID);
		setIsOpenOtherLocation(true);
		const url = `${apiUrl}store/listStore?brand_id=${item?.brandID}&latitude=${latitude}&longitude=${longitude}`;
		const res = await axios.get(url);
		if (res?.data?.status === 'ok') {
			const result = res?.data?.result || [];
			dispatch(setOutletBrand({ brandID: item?.brandID, outlets: result }));
		}
    setisLoadingOtherLocation(false);
	};

	const handleGetOutletList = useCallback(
		async (page = 1, limit = 10, isLoadMore = false) => {
			console.log('handleGetOutletList', isLoadMore);
			try {
				let url = `${apiUrl}store/listStore?unquieid=${uniqueID}&latitude=${latitude}&longitude=${longitude}&page=${page}&limit=${limit}`;
				const res = await axios.get(url);
				if (res?.data?.status === 'ok') {
					const result = res?.data?.result || [];
					const totalPage = res?.data?.total_pages || 0;
					const page = res?.data?.page || 0;
					const total = Number(res?.data?.total) || 0;
					const limit = res?.data?.limit || 0;
					if (isLoadMore) {
						const newItems = result.filter((item) => !outlets.some((outlet) => outlet.storeID === item.storeID));
						dispatch(
							setOutlet({
								list: [...outlets, ...newItems],
							}),
						);
					} else {
						dispatch(
							setOutlet({
								list: result,
							}),
						);
					}
					setTotalPage(totalPage);
					setPage(page);
					setTotal(total);
					setLimit(limit);
					setIsLoadMore(false);
				}
			} catch (error) {
				console.log('error: ', error);
			}
		},
		[page, limit, totalPage, latitude, longitude, uniqueID, isLoadMore],
	);

	useEffect(() => {
		handleGetOutletList();
	}, [latitude, longitude]);

	useEffect(() => {
		if (!isNearBottom || isLoadMore || page >= totalPage) return;
		setIsLoadMore(true);
		const nextPage = page + 1;
		handleGetOutletList(nextPage, limit, true);
	}, [isNearBottom]);

	return {
		outlets,
		loading,
		outletByLocation,
		handleOutletByLocation,
		isOpenOtherLocation,
		setIsOpenOtherLocation,
		isLoadMore,
    isLoadingOtherLocation,
    selectedStoreID,
	};
};

export default useOutlet;
