import { useState } from 'react';

const useToast = () => {
	const [toasts, setToasts] = useState([]);

	const showToast = (type, message, timeout = 1000) => {
		const id = Date.now();

		setToasts((prevToasts) => {
			const newToasts = [...prevToasts, { id, type, message }];

			if (newToasts.length > 0) {
				document.body.classList.add('message-overlay');

				const toastElement = document.createElement('div');

				let toastClass = 'bg-green-500';
				if (type === 'error') {
					toastClass = 'bg-red-500';
				} else if (type === 'warning') {
					toastClass = 'bg-yellow-500';
				} else if (type === 'info') {
					toastClass = 'bg-blue-500';
				}

				toastElement.className = `message-overlay-light ${toastClass} text-white p-4 rounded-md h-auto w-fit py-1 px-1 text-xs font-thin`;

				toastElement.innerText = message;
				document.body.appendChild(toastElement);

				setTimeout(() => {
					document.body.removeChild(toastElement);
					if (newToasts.length === 1) {
						document.body.classList.remove('message-overlay');
					}
				}, timeout);
			}

			return newToasts;
		});

		setTimeout(() => {
			setToasts((prevToasts) => {
				const filteredToasts = prevToasts.filter((toast) => toast.id !== id);
				if (filteredToasts.length === 0) {
					document.body.classList.remove('message-overlay');
				}
				return filteredToasts;
			});
		}, timeout);
	};

	return {
		toasts,
		showToast,
	};
};

export default useToast;
