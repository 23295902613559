import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { ShopOpenIcon, VerifyPinIcon } from '../../utils/svg';
import ImageLoader from '../ImageLoader';
import noConnectionPng from '../../common/images/revamp/images/noconnectionpng.png';

export const TooFar = ({ show = false, handleClickButton = () => {} }) => {
	return (
		<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${show ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
			<div className='flex justify-center'>
				<ShopOpenIcon />
			</div>
			<div className='flex flex-col w-full items-center text-center'>
				<div className='flex justify-center pt-4 text-base text-gray-900 font-semibold max-w-96'>Sorry, There are no restaurant available around your location</div>
				<div className='flex justify-center pt-2 text-[0.72rem] text-gray-700 font-normal'>Please change your location to find a good deals from a restaurant</div>
			</div>
			<button className='mt-8 w-full bg-white text-rose-violet flex justify-center font-semibold text-sm p-3 border-1 items-center border-solid border-rose-violet' onClick={handleClickButton}>
				Change Location
			</button>
		</div>
	);
};

export const HowToSetPinUltraValue = ({ onBack = () => {}, onSubmit = () => {}, showButton = false }) => {
	return (
		<div className='h-screen w-full flex justify-center items-center'>
			<div className='relative h-screen w-full max-w-lg overflow-y-hidden'>
				<div className={`absolute mt-5 w-full max-w-lg top-0`}>
					<div className='flex flex-row mx-4 mb-4'>
						<div className='w-full relative flex items-center'>
							<div className='flex justify-center items-center cursor-pointer z-10' onClick={onBack}>
								<ChevronLeftIcon className='h-8 w-8 text-gray-500 bg-gray-100 rounded-md p-1.5' />
							</div>
							<div className='absolute w-full left-0 flex justify-center text-gray-600 font-semibold text-base'>Cara buat PIN</div>
						</div>
					</div>
				</div>
				<div className='h-full w-full mt-16 overflow-y-scroll'>
					<div className='w-full max-h-48'>
						<ImageLoader src='./assets/img/uv-pin-step-1.png' alt='uv-pin-1' className='h-full w-full object-cover object-center' />
					</div>
					<div className='w-full max-h-48'>
						<ImageLoader src='./assets/img/uv-pin-step-2.png' alt='uv-pin-1' className='h-full w-full object-cover object-center' />
					</div>
					<div className='w-full max-h-48'>
						<ImageLoader src='./assets/img/uv-pin-step-3.png' alt='uv-pin-1' className='h-full w-full object-cover object-center' />
					</div>
					<div className='w-full max-h-48'>
						<ImageLoader src='./assets/img/uv-pin-step-4.png' alt='uv-pin-1' className='h-full w-full object-cover object-center' />
					</div>
					<div className='w-full max-h-48'>
						<ImageLoader src='./assets/img/uv-pin-step-5.png' alt='uv-pin-1' className='h-full w-full object-cover object-center' />
					</div>
					<div className={`w-full max-h-48 ${showButton && 'mb-32'}`}>
						<ImageLoader src='./assets/img/uv-pin-step-6.png' alt='uv-pin-1' className={`h-full ${!showButton ? 'max-h-64 object-top' : 'object-center'} w-full object-cover`} />
					</div>
				</div>
				<div className={`${showButton ? 'absolute' : 'hidden'} w-full px-2 left-0 bottom-0 bg-white`}>
					<button onClick={onSubmit} className='w-full py-2.5 my-4 bg-green-600 border-green-600 text-white text-sm'>
						Set up your PIN in Ultra Voucher
					</button>
				</div>
			</div>
		</div>
	);
};

export const SetPinUltraValue = ({ onSubmit = () => {} }) => {
	return (
		<div className='flex flex-col items-center justify-center mt-10 mx-5'>
			<VerifyPinIcon />
			<div className='mt-5 text-base text-center font-semibold text-gray-700'>Sorry, you haven’t created a PIN yet, Check the instruction on how to create a PIN</div>
			<button className='w-full bg-rose-violet text-white flex justify-center font-normal text-base border-0 items-center my-4 p-3 rounded-lg' onClick={onSubmit}>
				How to Create a PIN ?
			</button>
		</div>
	);
};

export const ConnectionChecker = ({ children }) => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		const handleOnline = () => setIsOnline(true);
		const handleOffline = () => setIsOnline(false);

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	if (isOnline) {
		return children;
	}

	return (
		<div className='flex flex-col justify-center items-center h-screen text-center p-5 bg-white'>
			<div className='mb-5'>
				<img src={noConnectionPng} alt='no-connection' className='h-auto w-30 object-cover object-center' />
			</div>
			<h2 className='text-2xl font-bold mb-2'>No Connection</h2>
			<p className='text-base text-gray-600 mb-5'>Oops! It seems you're currently offline.</p>
			<button className='px-5 py-2.5 text-base text-white bg-rose-violet rounded-lg w-80' onClick={() => window.location.reload()}>
				Try Again
			</button>
		</div>
	);
};
