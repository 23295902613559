import heart from "../../../common/images/revamp/icon/heart.svg";
import history from "../../../common/images/revamp/icon/history.svg";
import customerCare from "../../../common/images/revamp/icon/customercare.svg";

const useHeader = (props) => {

  /*======================== Others ======================== */

  const headerMenu = [
    {
      id: "click_btn_fav",
      name: "heart",
      icon: heart,
      to: "/favourites",
    },
    {
      id: "click_btn_list_history",
      name: "history",
      icon: history,
      to: "/history",
    },
    // {
    //   name: "customerCare",
    //   icon: customerCare,
    //   to: "/customer",
    // },
  ];

  /*======================== Return ======================== */

  return {
    headerMenu,
  };
};

export default useHeader;
