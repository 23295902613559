import useFormAddress from "./useFormAddress";
import locationPurpleIcon from "../../../../../../common/images/revamp/icon/location-purple.svg";
import UFInput from "../../../../../Input/UFInput";
import { twMerge } from "tailwind-merge";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { centerLocation } from "../../../../../../utils/constants";

const FormAddress = (props) => {
  console.log('FormAddress');
  /*======================== Props ======================== */

  const { onClose, type } = props;
  const {
    typeOptions,
    mapOptions,
    formValue,
    location,
    loading,
    onLoadMap,
    handleDragMap,
    handleOnClickMap,
    handleFormValue,
    handleValidate,
    handleDisableSubmit,
    handleSubmit,
    handleSearch,
  } = useFormAddress(props);

  let center = null;
  let zoom = 15;
  if (location?.lat && location?.lng) {
    center = {
      lat: location?.lat,
      lng: location?.lng
    }
  } else {
    center = centerLocation
    zoom = 4;
  }

  /*======================== Return ======================== */
  return (
    <div
      className={`pointer-events-auto z-[9999999] fixed top-0 h-screen w-screen !-mt-[calc(100vh-(100vh-(100vh/10)))] left-0 bg-white overflow-y-auto`}
    >
      <div
        className="absolute top-4 left-4 grid place-content-center cursor-pointer z-5"
        onClick={onClose}
      >
        <ArrowLeftIcon className="h-8 w-8 text-gray-900 bg-white rounded-full p-2 shadow-custom" />
      </div>

      <div className="flex flex-col">
        {/* Location */}
        <GoogleMap
          mapContainerClassName="h-[240px] w-full"
          clickableIcons={!loading}
          center={center}
          zoom={zoom}
          onClick={(e) => {
            handleOnClickMap(e);
          }}
          onLoad={onLoadMap}
          // onUnmount={onUnmount}
          options={mapOptions}
        >
          {centerLocation && location && (
            <Marker
              position={location}
              draggable={!loading}
              onDragEnd={(e) => {
                handleDragMap(e);
              }}
            />
          )}
        </GoogleMap>
        <div className="w-full h-full space-y-4 [&>*]:px-4 py-4  grow">
          <div className="flex justify-between items-center">
            <span className="text-black37 font-semibold text-sm">
              Add an address
            </span>
            <button
              id="btn_search_location"
              className="capitalize border border-solid border-ufdPurple text-ufdPurple py-1.5 px-3 text-center text-xs font-semibold rounded hover:bg-ufdPurple hover:text-white transition-all disabled:cursor-not-allowed disabled:opacity-50"
              onClick={handleSearch}
              disabled={loading}
            >
              Search
            </button>
          </div>

          <div className="flex gap-2.5 items-start">
            <img
              src={locationPurpleIcon}
              alt="location-purple"
              className="w-4 h-4"
            />
            <div className="grow">
              <div className="space-y-0.5">
                <p className="text-black37 text-sm font-semibold">
                  {location.name}
                </p>
                <p className="text-black37 text-xxs">{location.address}</p>
              </div>
            </div>
          </div>

          <div className="h-2 w-full bg-grayf4 px-0"></div>

          <div className="px-4 space-y-2">
            <UFInput
              id="click_location_name"
              label="Address Name"
              required
              type="text"
              placeholder="e.g. Home, Office"
              value={formValue.addressName}
              errorMessage={handleValidate("addressName")}
              onChange={(event) => handleFormValue("addressName", event.target.value)}
              maxLength={25}
              disabled={loading}
            />
            <UFInput
              id="click_location_detail"
              label="Address Details"
              optional
              type="text"
              placeholder="e.g. Floor, unit number"
              value={formValue.addressDetail}
              onChange={(event) =>
                handleFormValue("addressDetail", event.target.value)
              }
              maxLength={41}
              disabled={loading}
            />

            <div className="grid grid-cols-3 gap-4 justify-between">
              {typeOptions.map((item, index) => (
                <div
                  id={item?.id}
                  key={index}
                  className={twMerge(
                    "rounded-md border border-solid border-grayD3 bg-white text-black37 py-2 px-[14px] flex gap-2.5 justify-center items-center",
                    item.value === formValue.type &&
                    "text-ufdPurple border-ufdPurple",
                    loading && "pointer-events-none opacity-50"
                  )}
                  onClick={() => handleFormValue("type", item.value)}
                >
                  <img
                    src={
                      item.value === formValue.type
                        ? item.iconActive
                        : item.iconInactive
                    }
                    alt={item.value}
                    className="w-4.5 h-4.5"
                  />
                  <span className="font-semibold text-xs">{item.label}</span>
                </div>
              ))}
            </div>

            <UFInput
              id="click_contact_name"
              label="Contact Name"
              required
              type="text"
              placeholder="e.g. Jhon doe"
              value={formValue.contactName}
              errorMessage={handleValidate("contactName")}
              onChange={(event) =>
                handleFormValue("contactName", event.target.value)
              }
              maxLength={25}
              disabled={loading}
            />

            <UFInput
              id="click_contact_nomor"
              label="Contact Number"
              required
              type="tel"
              placeholder="e.g. 081234567890"
              value={formValue.contactNumber}
              errorMessage={handleValidate("contactNumber")}
              onChange={(event) =>
                handleFormValue("contactNumber", event.target.value)
              }
              maxLength={15}
              disabled={loading}
            />

            <button
              id="btn_save_address"
              className="capitalize border border-solid border-ufdPurple bg-ufdPurple text-white w-full py-2.5 text-center text-xs font-semibold rounded hover:bg-ufdPurple/80 transition-all disabled:cursor-not-allowed disabled:opacity-50"
              onClick={handleSubmit}
              disabled={type == "edit" ? false : handleDisableSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddress;
