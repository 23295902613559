import React, { useEffect, useMemo, useState } from 'react';
import { formatRupiah, removeHTML } from '../../utils/helper';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import Notes from '../../components/Notes/index';
import { PencilEditIcon } from '../../utils/svg';
import ImageLoader from '../ImageLoader';
import * as Yup from 'yup';
import { useFormik } from 'formik';

export const ComboDetail = ({ product = {}, item = null, handleSubmitItem = () => {}, isEdit = false }) => {
	const desc = removeHTML(product?.short_description || '');

	const [total, setTotal] = useState(1);
	const [note, setNote] = useState('');
	const [comboSet, setComboSet] = useState([]);

	const productDetailsSchema = () => {
		return Yup.lazy((_, { index }) => {
			const validation = product?.comboset?.[index];
			let schema = Yup.array();

			if (Number(validation?.min_select)) {
				schema = schema.min(Number(validation?.min_select ?? 0), `Pilih ${validation?.min_select ?? 0} ${validation?.combo_name}`);
			}
			if (Number(validation?.max_select)) {
				schema = schema.max(Number(validation?.max_select ?? 0), `Maksimal ${validation?.max_select ?? 0} ${validation?.combo_name}`);
			}
			if (Number(validation?.min_select) || Number(validation?.max_select)) {
				schema = schema.required();
			}
			return schema;
		});
	};
	const comboSchema = Yup.object({
		comboset: Yup.array().of(
			Yup.object({
				productDetails: productDetailsSchema(),
			}),
		),
	});

	const formik = useFormik({
		initialValues: {
			comboset: item
				? item?.comboset?.map((combo) => ({
						productDetails: combo?.productDetails || [],
				  }))
				: product?.comboset?.map(() => ({
						productDetails: [],
				  })),
		},
		validationSchema: comboSchema,
		validateOnChange: true,
		isInitialValid: item ? true : false,
	});

	const { isValid, errors, setFieldValue, handleSubmit: handleSubmitFormik } = formik;

	const totalPrice = useMemo(() => {
		const comboPrice = comboSet?.reduce((sum, comb) => {
			const detailPriceSum = comb?.productDetails?.reduce((detailSum, detail) => {
				return detailSum + Number(detail?.productPrice || 0);
			}, 0);
			return sum + detailPriceSum;
		}, 0);
		const productPrice = Number(product?.product_price);
		return (productPrice + comboPrice) * total;
	}, [comboSet, total]);

	const handleSubmit = async () => {
		handleSubmitFormik();
    const validate = await formik.validateForm();
    const isManualValidation = Object.keys(validate).length === 0;
		console.log('ComboDetail handleSubmit');
		if (isValid || isManualValidation) {
			console.log('isValid: ', isValid, isManualValidation);
			const dataPost = {
				productID: product?.product_id,
				quantity: total,
				type: !isEdit ? 'add' : 'update',
				productType: 'Combo',
				specialNotes: note,
				comboset: comboSet,
				itemID: !isEdit ? null : item?.itemID,
				price: Number(item?.itemUnitPrice || 0),
				totalPrice: totalPrice,
			};

			handleSubmitItem(dataPost);
		}
	};

	const handleComboChange = (comboIndex, comboProduct, comboSetProduct) => {
		console.log('handleComboChange');
		const productDetails = {
			productID: comboSetProduct?.product_id,
			productName: comboSetProduct?.product_name,
			productSKU: comboSetProduct?.product_sku,
			productPrice: Number(comboSetProduct?.product_price || 0),
			quantity: 1,
		};

		setComboSet((prev) => {
			const updatedComboSet = [...prev];
			updatedComboSet[comboIndex] = {
				comboSetId: comboProduct?.combo_id,
				comboSetname: comboProduct?.combo_name,
				productDetails: [productDetails],
				min_max_flag: comboProduct?.max_select || 1,
			};
			return updatedComboSet;
		});

		setFieldValue(`comboset[${comboIndex}]`, {
			productDetails: [productDetails],
		});
	};

	useEffect(() => {
		if (!isEdit) return;
		setTotal(Number(item?.itemQuantity || 1));
		setNote(item?.itemNotes || '');
		const updatedComboSet =
			item?.comboset?.map((combo) => ({
				comboSetId: combo?.comboSetId,
				comboSetname: combo?.comboSetname,
				productDetails: combo?.productDetails.map((detail) => ({
					productID: detail?.productID,
					productName: detail?.productName,
					productSKU: detail?.productSKU,
					productPrice: Number(detail?.productPrice || 0),
					quantity: 1,
				})),
			})) || [];
		setComboSet(updatedComboSet);
	}, [item]);

	return (
		<>
			<div className={`bg-white rounded-t-xl p-4 shadow-custom transition-transform duration-500 ease-in-out h-full max-h-[39rem] overflow-y-scroll scrollbar-hide ${true ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
				<div className='hidden justify-center'>
					<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
				</div>
				<div className='relative mt-4 h-44'>
					<ImageLoader alt={product?.product_slug} src={product?.product_thumbnail} />
				</div>
				<div className='mt-4 text-gray-900 font-semibold text-sm'>{product?.product_name || ''}</div>
				<div className='text-sm flex items-center pt-2'>
					<div className='font-semibold pr-1 text-gray-800'>{formatRupiah(product?.product_price)}</div>
					{Boolean(Number(product?.product_cost)) && <div className='line-through text-gray-500'>{formatRupiah(Number(product?.product_cost))}</div>}
				</div>
				<div className='text-gray-400 text-xs pt-2 font-normal'>{desc}</div>
				<div className='mt-5'>
					{product?.comboset?.map((combo, index) => {
						return (
							<div key={index}>
								<div className='text-sm text-gray-900 font-semibold my-4'>{combo?.combo_name}</div>
								<div className='text-red-500 text-xs'>{errors?.comboset?.[index]?.productDetails}</div>
								{combo?.products?.map((comboProduct, idx) => {
									const comboPrice = Number(comboProduct?.product_price) || 0;

									const isSelected =
										comboSet?.some((selectedCombo) => {
											return selectedCombo?.productDetails?.some((detail) => {
												const isMatching = detail.productID === comboProduct?.product_id;
												return isMatching;
											});
										}) ?? false;

									return (
										<label key={`${index}_${idx}`} htmlFor={`${index}_${idx}`} className='flex items-center justify-between h-12 border-b-1 border-gray-200 border-solid'>
											<div htmlFor={`${index}_${idx}`} className='text-xs text-gray-900 font-semibold flex'>
												{comboProduct?.product_name} {Boolean(comboPrice) && <div className='text-gray-500 text-xs font-normal pl-2'>{`+${formatRupiah(comboPrice)}`}</div>}
											</div>
											<input
												id={`${index}_${idx}`}
												type={combo?.multipleselection_apply === '1' ? 'checkbox' : 'radio'}
												value={comboProduct?.product_id}
												name={`combo_${index}`}
												className='w-4 h-4 bg-white !border-byzantium after:!bg-byzantium focus:!shadow-none'
												onChange={() => {
													handleComboChange(index, combo, comboProduct);
												}}
												checked={isSelected}
											/>
										</label>
									);
								})}
							</div>
						);
					})}
				</div>
				<div className='mt-5'>
					<Notes item_id={item?.itemID ? item?.itemID : ''} callback={setNote} notes={note} />
				</div>
			</div>
			<div className='bg-white w-full p-4 z-auto shadow-custom'>
				<div className='flex justify-between items-center'>
					<div className='text-gray-900 text-xs font-semibold'>Item quantity</div>
					<div className='bg-white p-1 rounded-md cursor-pointer flex items-center border-1 border-solid border-byzantium text-xs'>
						<MinusIcon
							className='h-5 w-5 text-byzantium'
							onClick={() =>
								setTotal((prev) => {
									if (prev === 0) return 0;
									return prev - 1;
								})
							}
						/>
						<input
							type='text'
							className='!bg-white !m-0 !p-0 !h-5 !w-8 text-center text-black font-semibold text-xs'
							value={total}
							disabled
							onChange={(e) => {
								const value = e.target.value;
								const num = value.replace(/[^0-9]/g, '');
								setTotal(num);
							}}
						/>
						<PlusIcon className='h-5 w-5 text-byzantium' onClick={() => setTotal((prev) => prev + 1)} />
					</div>
				</div>
				<button
					onClick={() => {
						if (!isEdit && item) return;
						console.log('handleClick button ComboSummary');
						return handleSubmit();
					}}
					id='click_btn_add_to_cart'
					disabled={!total}
					className={`mt-2 w-full disabled:cursor-not-allowed  ${!total ? 'bg-gray-400' : 'bg-rose-violet'} text-white flex justify-center font-normal text-xs p-3 items-center border-0`}>
					{isEdit ? 'Update Cart' : 'Add to Cart'}
				</button>
			</div>
		</>
	);
};

const ComboSummary = ({ item = {}, handleSubmit = (data) => {}, handleEdit = (item) => {} }) => {
	console.log('ComboSummary');
	const [total, setTotal] = useState(1);
	const [note, setNote] = useState('');
	const [comboSet, setComboSet] = useState([]);

	const totalPrice = useMemo(() => {
		const comboPrice = comboSet?.reduce((sum, comb) => {
			const detailPriceSum = comb?.productDetails?.reduce((detailSum, detail) => {
				return detailSum + Number(detail?.productPrice || 0);
			}, 0);
			return sum + detailPriceSum;
		}, 0);

		const productPrice = Number(item?.itemUnitPrice);
		return (productPrice + comboPrice) * total;
	}, [comboSet, total]);

	useEffect(() => {
		setTotal(Number(item?.itemQuantity || 1));
		setNote(item?.itemNotes || '');
		const updatedComboSet =
			item?.comboset?.map((combo) => ({
				comboSetId: combo?.comboSetId,
				comboSetname: combo?.comboSetname,
				productDetails: combo?.productDetails.map((detail) => ({
					productID: detail?.productID,
					productName: detail?.productName,
					productSKU: detail?.productSKU,
					productPrice: Number(detail?.productPrice || 0),
					quantity: 1,
				})),
			})) || [];
		setComboSet(updatedComboSet);
	}, [item]);

	const updateTotal = (type) => {
		console.log('updateTotal');
		let prevTotal = Number(item?.itemQuantity);
		if (type === 'plus') {
			prevTotal = prevTotal + 1;
		} else {
			prevTotal = prevTotal !== 0 ? prevTotal - 1 : 0;
		}

		const dataPost = {
			productID: item?.productID,
			quantity: prevTotal,
			type: 'update',
			productType: 'Combo',
			specialNotes: note,
			comboset: comboSet,
			itemID: item?.itemID,
			price: Number(item?.itemUnitPrice),
			totalPrice: totalPrice,
			itemNotes: note,
		};
		handleSubmit(dataPost);
	};

	return (
		<div key={`combo_${item?.itemID}`} className='mt-4'>
			{item?.comboset?.map((combo, index) => {
				return (
					<div key={index} className='w-full flex items-center'>
						<div className='text-sm text-gray-900 font-semibold'>{combo?.comboSetname}:</div>
						{combo?.productDetails?.map((comboProduct, idx) => {
							return (
								<div key={`${index}_${idx}`} className='flex items-center pl-2'>
									<div key={`${index}_${idx}`} className='text-xs font-normal text-gray-800'>
										{comboProduct?.productName ?? ''}
									</div>
									{Boolean(Number(comboProduct?.productPrice || 0)) && <div className='text-gray-500 text-xs font-normal pl-1'>{`+${formatRupiah(comboProduct?.productPrice)}`}</div>}
								</div>
							);
						})}
					</div>
				);
			})}
			<div className='w-full flex mt-1'>
				<div className='text-xs font-semibold text-gray-800'>Notes:</div>
				<div className='text-xs font-normal text-gray-800 pl-2'>{note ?? ''}</div>
			</div>
			<div className='text-sm text-gray-900 font-semibold mt-1'>{formatRupiah(totalPrice || 0)}</div>
			<div className='flex justify-between mt-2'>
				<div
					className='flex border-1 border-solid border-gray-300 rounded-full py-1 px-2 items-center'
					onClick={() => {
						handleEdit(item);
					}}>
					<PencilEditIcon />
					<div className='pl-2 text-gray-900 text-xs font-semibold'>Edit</div>
				</div>
				<div className=''>
					<div className='bg-white p-1 rounded-md cursor-pointer flex items-center border-solid border-1 border-byzantium'>
						<MinusIcon className='h-5 w-5 text-byzantium' onClick={() => updateTotal('minus')} />
						<input type='text' className='!bg-white !m-0 !p-0 !h-5 !w-8 text-center !text-byzantium font-normal text-xs' value={total} disabled />
						<PlusIcon className='h-5 w-5 text-byzantium' onClick={() => updateTotal('plus')} />
					</div>
				</div>
			</div>
		</div>
	);
};

const ProductCombo = ({ product = {}, cart = null, handleCart = (data) => {}, setCartItem = (data) => {}, edit = false }) => {
	console.log('ProductCombo');
	const currentCartItem = cart?.item?.filter((item) => item.productID === product?.product_id) || null;

	const desc = removeHTML(product?.short_description || '');
	const [item, setItem] = useState(null);
	const [isAddMore, setIsAddMore] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const handleSubmit = (data = {}) => {
		console.log('ProductCombo handleSubmit');
		const isClose = !isAddMore && !isEdit;
		handleCart(data, !isClose);
	};

	const handleEdit = (item = {}) => {
		console.log('handleEdit');
		setItem(item);
		setIsEdit(true);
	};

	const handleAddMore = () => {
		console.log('handleAddMore');
		setIsAddMore(true);
	};

	return (
		<>
			{currentCartItem?.length && !isAddMore && !isEdit ? (
				<>
					<div className={`bg-white rounded-t-xl p-4 shadow-custom transition-transform duration-500 ease-in-out ${true ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
						<div className='hidden justify-center'>
							<div className='bg-gray-300 h-1.5 w-10 rounded-full' />
						</div>
						<div className='mt-4 text-gray-900 font-semibold text-sm'>{product?.product_name || ''}</div>
						<div className='text-gray-400 text-xs pt-2 font-normal'>{desc}</div>
						<div className='mt-5'>
							{currentCartItem?.map((item) => {
								return <ComboSummary item={item} handleSubmit={handleSubmit} handleEdit={handleEdit} />;
							})}
						</div>
					</div>
					<div className='bg-white w-full p-4 z-auto shadow-custom'>
						<button onClick={handleAddMore} className={`mt-2 w-full disabled:cursor-not-allowed bg-byzantium text-white flex justify-center font-normal text-xs p-3 items-center border-0`}>
							{'Add another custom order'}
						</button>
					</div>
				</>
			) : (
				<ComboDetail product={product} item={item} isEdit={isEdit} handleSubmitItem={handleSubmit} />
			)}
		</>
	);
};

export default ProductCombo;
