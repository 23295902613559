import { twMerge } from 'tailwind-merge';
import { formatRupiah } from '../../../../utils/helper';
import star from '../../../../common/images/revamp/icon/star.svg';
import React from 'react';
import ImageLoader from '../../../ImageLoader';
import { useHistory } from 'react-router';

const SearchProduct = React.memo((props) => {
	const { products, storeSlug, storeOpen } = props;
	const history = useHistory();

	return (
		<>
			<div className=''>
				<div className='grid grid-flow-col auto-cols-min overflow-x-auto snap-x snap-mandatory scroll-smooth px-4 scroll-px-4 gap-2 scrollbar-hide mb-2'>
					{products?.map((product, index) => (
						<div
							key={index}
							className={`snap-start w-[90px] ${storeOpen === '1' ? 'cursor-pointer' : ''}`}
							onClick={() => {
								if (storeOpen === '1') {
									history.push(`/outlet/${storeSlug}`);
								}
							}}>
							<div className={twMerge('mb-1.5 relative transition-all')}>
								<div className='w-[5.6rem] h-[5.6rem]'>
									<ImageLoader src={product?.productImage} altImage={product?.productName} />
								</div>
							</div>

							<div className='space-y-0.5'>
								{product?.rating && (
									<div className='flex items-center gap-1'>
										<img src={star} alt='star' className='!w-auto' />
										<span className='text-xxs font-medium text-black37'>
											{product?.rating || 0} ({product?.totalRating || 0})
										</span>
									</div>
								)}
								<p className='line-clamp-2 text-xxs font-medium text-black37'>{product?.productName}</p>
								<div className='line-clamp-1 text-xxs text-black37 font-semibold'>
									{formatRupiah(product?.productPrice)}
									<span className={`line-through text-[7px] text-xxxs text-[#909090] ml-1 ${!Number(product?.productCost) && 'hidden'}`}>{formatRupiah(product?.productCost)}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
});

export default SearchProduct;
