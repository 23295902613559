import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueID } from '../../../utils/constants';
import { getTagList, setTag } from '../../../store/reducers/tag';

const useTags = () => {
	/*======================== Store ======================== */

	const dispatch = useDispatch();
	const { data: tags, loading } = useSelector((state) => state.tag);
	// const { params: outletParams } = useSelector((state) => state.outlet);

	/*======================== UseState ======================== */

	const [selected, setSelected] = useState('');
	// const [timeoutTags, setTimeoutTags] = useState(null);

	/*======================== Handler ======================== */

	const handleSelectTag = (value) => {
		console.log('handleSelectTag: ', value);
		// clearTimeout(timeoutTags);
		// setTimeoutTags(
		//   setTimeout(() => {
		//     dispatch({
		//       type: GET_OUTLET_LIST,
		//       params: {
		//         ...outletParams,
		//         limit: 10,
		//         page: 1,
		//         tags: value === selected ? undefined : value,
		//       },
		//       isReset: true,
		//     });
		//   }, 200)
		// );
		// if (value === selected) {
		//   setSelected("");
		//   return;
		// }
		setSelected(value);
		dispatch(setTag({ selected: value }));
	};

	useEffect(() => {
		dispatch(
			getTagList({
				unquieid: uniqueID,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		selected,
		tags,
		loading,
		handleSelect: handleSelectTag,
	};
};

export default useTags;
