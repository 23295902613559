import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useDeliveryLocation = () => {
  console.log('useDeliveryLocation');
  const history = useHistory();
	const [openDetail, setOpenDetail] = useState(false);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        setOpenDetail(false);
        return false;
      }
      return true;
    });
    return () => {
      unblock();
    };
  }, [openDetail, history]);

	const handleOpenDetail = () => {
		setOpenDetail((prev) => !prev);
	};

	return {
		openDetail,
		handleOpenDetail,
	};
};

export default useDeliveryLocation;
