import React from 'react';
import { formatRupiah } from '../../../utils/helper';
import star from '../../../common/images/revamp/icon/star.svg';
import motorbike from '../../../common/images/revamp/icon/motorbike.svg';
import tagPrice from '../../../common/images/revamp/icon/tag-purple.svg';
import discount from '../../../common/images/revamp/icon/discount.svg';
import { twMerge } from 'tailwind-merge';
import useSearchList from './useSearchList';
import SearchProduct from './Product';
import { useHistory } from 'react-router';
import FoodImg from '../../../common/images/food.png'
import { ClockIcon } from '@heroicons/react/24/solid';

const SearchList = (props) => {
	const { outlets } = props;
  console.log('outlets: ', outlets);
	const { handleTotalPrice, handleTotalQuantity } = useSearchList();
	const history = useHistory();

	return (
		<div>
			<div className={twMerge('py-3 flex flex-col gap-[26px] pb-28')}>
				{outlets?.map((item, index) => (
					<div key={index} className='space-y-4'>
						<div
							className='px-4 flex justify-between gap-3'
							onClick={() => {
								if (item?.storeOpen === '1') {
									history.push(`/outlet/${item?.storeSlug}`)
								}
							}}>
							<img src={item?.storeImage || FoodImg} alt='outlet-img' className='rounded-lg w-24 h-24 object-cover aspect-square' />
							<div className='grow space-y-1'>
								<div className={`${item?.storeOpen === '0' ? 'flex' : 'hidden'} items-center`}>
									<ClockIcon class='h-4 w-4 text-red-500 mr-1' />
									<div className='text-xs font-normal text-gray-900'>Closed {item?.outletOpenTime && `- Open at ${item?.outletOpenTime} AM Tommorow`}</div>
								</div>
								<p className='mb-0 font-semibold text-black37 line-clamp-1'>{item?.storeName}</p>
								{item?.storeRating && (
									<div className='flex items-center gap-1'>
										<img src={star} alt='star' />
										<span className='font-medium text-sm text-black37'>
											{item?.storeRating || 0} ({item.totalRating || 0})
										</span>
									</div>
								)}
								<div className='flex items-center gap-1'>
									<img src={motorbike} alt='motorbike' />
									<span>{item?.distance ? parseFloat(item?.distance || 0).toFixed('2') : '0'} km</span>
								</div>
								{item?.offerInfo && (
									<div className='flex items-center gap-1'>
										<img src={tagPrice} alt='tagPrice' />
										<span className='font-medium text-sm text-black37'>{item?.offerInfo}</span>
									</div>
								)}
							</div>
						</div>
						{item?.products?.length > 0 && <SearchProduct products={item?.products} storeSlug={item?.storeSlug} storeOpen={item?.storeOpen} />}
					</div>
				))}
			</div>
			{handleTotalQuantity() > 0 && (
				<div className='fixed bottom-0 shadow-custom w-full p-4 bg-white z-20'>
					<div className='flex space-x-1.5 items-center mb-2'>
						<img src={discount} alt='discount' />
						<span className='text-black37 text-xs'>Yay, you're saving 10k. Add more and save up to 50k!</span>
					</div>
					<button className='bg-ufdPurple w-full text-white py-3 px-4 font-semibold flex justify-between gap-2'>
						<div>{handleTotalQuantity()} item in cart</div>
						<div>{formatRupiah(handleTotalPrice())}</div>
					</button>
				</div>
			)}
		</div>
	);
};

export default SearchList;
