import React from 'react';
import { Link } from "react-router-dom";
import orderEmpty from "../../../common/images/abandoned-cart.svg";

function NoOrders() {
  return (
    <div className="w-full h-[60vh] flex flex-col justify-center items-center px-4">
      <img src={orderEmpty} alt="No orders" />
      <h2 className="text-base font-semibold">Fill up your empty cart with good food</h2>
      <p className="text-xs font-normal">You seem to have not decided what to order,</p>
      <p className="text-xs font-normal">let us help you.</p>
      <Link to="/" className="w-full mt-8" id="click_btn_explore_ufood_list_history">
        <button className="button w-full">
          Explore Ufood
        </button>
      </Link>
    </div>
  );
}

export default NoOrders;
