import { BottomSheet } from 'react-spring-bottom-sheet';
import useDeliveryLocation from './useDeliveryLocation';
import DeliveryLocationDetail from './LocationDetail';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const DeliveryLocation = () => {
	const { openDetail, handleOpenDetail } = useDeliveryLocation();
	const { savedIsOnSearch } = useSelector((state) => state.location);
	const address = useSelector((store) => store?.user?.address || '');

	return (
		<div className='relative'>
			<div className='flex items-center max-h-10 gap-1.5 grow'>
				<div id='back_btn_home' onClick={() => console.log('back_btn_home')} className=''>
					<ArrowLeftIcon className='w-5 h-5 cursor-pointer' strokeWidth={2} />
				</div>
				<div
					id='click_delivery_to'
					className='grow'
					onClick={(event) => {
						event.preventDefault();
						if (!openDetail) handleOpenDetail();
					}}>
					<p className='mb-0'>Deliver To</p>
					<div className='flex items-center'>
						<p className='m-0 mr-2 truncate font-semibold max-w-[calc(100vw-240px)]'>{address}</p>
						<ChevronDownIcon className='w-4 h-4' strokeWidth={2} />
					</div>
				</div>
			</div>
			<BottomSheet
				open={openDetail}
				onDismiss={handleOpenDetail}
				snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
				header={<p className='m-0 py-2 text-left text-lg text-black37 font-semibold'>{savedIsOnSearch ? 'Change Your Saved Location' : 'Select Location'}</p>}
				className='relative pointer-events-auto z-[1000]'>
				<DeliveryLocationDetail handleCloseDelivery={handleOpenDetail} />
			</BottomSheet>
		</div>
	);
};

export default DeliveryLocation;
