import { useDispatch, useSelector } from "react-redux";
import { getRecentList } from "../../../../../store/reducers/location";
import { useEffect } from "react";
import { setUser } from "../../../../../store/reducers/user";

const useDeliveryRecent = (props) => {
  /*======================== Props ======================== */

  const { handleCloseDelivery } = props;

  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user?.customerID || "");
  const { recentData: recentLocation, recentLoading: loading } = useSelector(
    (state) => state.location
  );

  /*======================== Handler ======================== */

  const handleSelectLocation = (location) => {
    console.log('handleSelectLocation: ', location);
    const {
      address,
      latitude,
      longitude,
      country,
      state,
      city,
      village,
      district,
      postal_code,
    } = location;

    dispatch(
      setUser({
        address,
        latitude,
        longitude,
        country,
        state,
        city,
        village,
        district,
        postalCode: postal_code,
      })
    );
    handleCloseDelivery();
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    dispatch(getRecentList(user_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*======================== Return ======================== */

  return {
    loading,
    recentLocation,
    handleSelectLocation,
  };
};

export default useDeliveryRecent;
