import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-spring-bottom-sheet/dist/style.css';
import './common/css/style.css';
import RouteConfig from './router/Router';
import './tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<RouteConfig />
			</Router>
		</PersistGate>
	</Provider>,
);
