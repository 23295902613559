import axios from 'axios';
import { googleMapApiKey } from '../utils/constants';

export const getDetailLocationFromLatLong = async (latitude, longitude) => {
	console.log('getDetailLocationFromLatLong');
	try {
		const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapApiKey}`);

		if (response.data.status === 'OK') {
			const results = response.data.results[0];
			let country = '';
			let state = '';
			let city = '';
			let village = '';
			let district = '';
			let postalCode = '';

			results?.address_components?.forEach((item) => {
				if (item.types.includes('country')) {
					country = item.long_name;
				}
				if (item.types.includes('administrative_area_level_1')) {
					state = item.long_name;
				}
				if (item.types.includes('administrative_area_level_2')) {
					city = item.long_name;
				}
				if (item.types.includes('administrative_area_level_3')) {
					village = item.long_name;
				}
				if (item.types.includes('administrative_area_level_4')) {
					district = item.long_name;
				}
				if (item.types.includes('postal_code')) {
					postalCode = item.long_name;
				}
			});
			const address = {
				address: results?.formatted_address,
				latitude: latitude,
				longitude: longitude,
				country,
				state,
				city,
				village,
				district,
				postalCode,
			};

			return address;
		} else {
			throw new Error(`Geocoding API error: ${response.data.status}`);
		}
	} catch (error) {
		console.error('Error fetching location details:', error);
		return null;
	}
};

export const getCurrentLocation = async () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('position: ', position);
          resolve(position.coords);
        },
        (error) => {
          let errorMessage;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = 'User denied the request for Geolocation.';
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = 'Location information is unavailable.';
              break;
            case error.TIMEOUT:
              errorMessage = 'The request to get user location timed out.';
              break;
            case error.UNKNOWN_ERROR:
              errorMessage = 'An unknown error occurred.';
              break;
            default:
              errorMessage = 'An unexpected error occurred.';
          }
          console.log('errorMessage: ', errorMessage);
          resolve(null);
        },
        {
          enableHighAccuracy: true,
          timeout: 3000,
          maximumAge: 0
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      resolve(null);
    }
  });
};
