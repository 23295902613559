import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiUrl } from '../../utils/constants';
import { formatRupiah } from '../../utils/helper';
import Loader from '../../components/Loader/index';
import { useHistory } from 'react-router';
import { ArrowLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import { CopyIcon, WaitingPaymentIcon } from '../../utils/svg';
import { formatDate } from '../../utils/helper';
import useToast from '../../components/Toast/index';
import { useDispatch, useSelector } from 'react-redux';
import { setPayment, clearPayment } from '../../store/reducers/payment';
import { clearCart } from '../../store/reducers/cart';
import { clearVoucher } from '../../store/reducers/voucher';
import { ListPaymentMethodAll } from '../../utils/constants';

const Payment = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const session_token = useSelector((store) => store.payment?.session_token);
	const paymentData = useSelector((store) => store.payment?.data);
	console.log('paymentData: ', !paymentData);
	const date = useSelector((store) => store.payment?.date);
	const timeLeft = useSelector((store) => store.payment?.timeLeft);
	const status = useSelector((store) => store.payment?.status);

	const [isLoadingCheckPayment, setIsLoadingCheckPayment] = useState(false);
	const [expiredDate, setExpiredDate] = useState(null);
	const [formattedTime, setFormattedTime] = useState('00:15:00');

	const [openIndex, setOpenIndex] = useState(null);

	const { showToast } = useToast();

	const toggleOpen = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	useEffect(() => {
		if (!date || status === 'EXPIRED') {
			return setFormattedTime('EXPIRED');
		}
		const newDate = new Date(date);
		newDate.setMinutes(newDate.getMinutes() + 15);
		setExpiredDate(newDate);
	}, [date]);

	const handleGetPaymentStatus = async (loading = false) => {
		try {
			loading && setIsLoadingCheckPayment(true);
			const data = new FormData();
			data.append('session_token', session_token);
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			const url = `${apiUrl}status-payment`;
			const res = await axios.post(url, data, config);
			if (res?.data?.status === 'ok') {
				const result = res?.data?.data || {};
				if (result?.status === 'SUCCESS') {
					dispatch(clearCart());
					dispatch(clearPayment());
					dispatch(clearVoucher());
					history.push({
						pathname: '/payment/success',
						state: {
							payment: result,
						},
					});
				}
				if (result?.is_expired) {
					dispatch(
						setPayment({
							timeLeft: 0,
							status: 'EXPIRED',
						}),
					);
				}
			}
			loading && setIsLoadingCheckPayment(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoadingCheckPayment(false);
		}
	};

	useEffect(() => {
		if (!session_token || status === 'EXPIRED') return;

		const formatTime = (seconds) => {
			const minutes = String(Math.floor(seconds / 60)).padStart(2, '0');
			const secs = String(seconds % 60).padStart(2, '0');
			return `00:${minutes}:${secs}`;
		};

		const interval = setInterval(() => {
			handleGetPaymentStatus();

			if (timeLeft > 0) {
				dispatch(
					setPayment({
						timeLeft: timeLeft - 1,
					}),
				);
				setFormattedTime(formatTime(timeLeft - 1));
			} else {
				clearInterval(interval);
				setFormattedTime('EXPIRED');
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [session_token, timeLeft, dispatch, showToast]);

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				console.log('copy', text);
				showToast('info', 'copied');
			})
			.catch((err) => {
				console.error('Failed to copy: ', err);
			});
	};

	return (
		<>
			{isLoadingCheckPayment && <Loader overlay />}
			<div className='h-screen w-full flex justify-center items-center bg-white'>
				<div className='relative h-screen w-full max-w-lg'>
					<div className={`absolute z-10 mt-5 w-full`}>
						<div className='flex flex-row justify-between mx-4'>
							<div className='flex justify-center items-center cursor-pointer' onClick={() => history.push('/summary')} id='click_back_btn_payment'>
								<ArrowLeftIcon className='h-8 w-8 text-gray-900 p-1.5' />
								<div className='pl-2 text-base text-gray-900 font-semibold truncate max-w-72'>Payment</div>
							</div>
						</div>
						<div className='border-b-1 border-solid pb-2 border-gray-200' />
						<div className={`mx-4 mt-4`}>
							<div className='text-sm text-gray-900 font-semibold'>Detail Transaction</div>
							<div className={`flex justify-between items-center mt-4`}>
								<div>
									<div className='text-xs text-gray-500 font-thin flex justify-start items-center'>Order Number</div>
									<div className='text-xs text-gray-500 font-thin mt-2 flex justify-start items-center'>Purchased On</div>
									<div className='text-xs text-gray-500 font-thin mt-2 flex justify-start items-center'>Status</div>
								</div>
								<div>
									<div className='flex items-center justify-end gap-2'>
										<div className='text-xs text-gray-900 font-semibold'>{paymentData?.order_id}</div>
										<div
                      id='click_copy_order_number_payment'
											onClick={() => {
												console.log('click copy order number');
												copyToClipboard(paymentData?.order_id);
											}}>
											<CopyIcon />
										</div>
									</div>
									<div className='text-xs text-gray-900 font-semibold mt-2 flex justify-end items-center'>{formatDate(date, 'D MMMM YYYY, h:mm A')}</div>
									<div className='text-xs text-star font-normal mt-2 flex justify-end items-center'>
										{paymentData?.payment_status === 'WAITING' ? 'Waiting Payment' : status === 'EXPIRED' ? 'EXPIRED' : paymentData?.payment_status}
									</div>
								</div>
							</div>
						</div>
						<div className='mx-4 mt-8 mb-28'>
							<div className='mt-4 border-solid border-1 border-gray-300 rounded-lg px-4 py-2.5'>
								<div className={`flex justify-between`}>
									<div className={`flex`}>
										<WaitingPaymentIcon />
										<div className='pl-2'>
											<div className='text-gray-900 text-sm font-semibold'>Complete payment in</div>
											<div className='text-gray-500 text-xs font-normal pt-1'>{formatDate(expiredDate, 'dddd, MMMM D, YYYY HH:mm')}</div>
										</div>
									</div>
									<div className='bg-red-100 flex h-5 items-center p-2 rounded-full'>
										<ClockIcon className='h-4 w-4 text-red-500' />
										<div className='text-xs font-semibold pl-1 text-red-500'>{formattedTime}</div>
									</div>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-300 mt-4' />
								<div className={`flex justify-between items-center mt-4`}>
									<div className='flex flex-col'>
										<div className='text-gray-500 text-xs font-normal'>Virtual Account Number</div>
										<div className='flex items-center pt-1'>
											<div className='text-gray-800 text-base font-bold'>{paymentData?.payment_generated}</div>
											<div
                        id='click_copy_va_payment'
												className='pl-1'
												onClick={() => {
													console.log('copy va number');
													copyToClipboard(paymentData?.payment_generated);
												}}>
												<CopyIcon />
											</div>
										</div>
									</div>
									<div className='w-auto h-auto'>
										<img
											alt={paymentData?.payment_category?.payment_method?.[0]?.code ?? ''}
											src={paymentData?.payment_category?.payment_method?.[0]?.image_url}
											className={`${
												ListPaymentMethodAll?.find((method) => method?.code === paymentData?.payment_category?.payment_method?.[0]?.code)?.image_size_payment || 'w-11'
											} h-auto object-contain object-center`}
										/>
									</div>
								</div>
								<div className={`flex flex-col mt-8`}>
									<div className='text-gray-900 text-xs font-normal'>Total Payment</div>
									<div className='flex items-center pt-1'>
										<div className='text-gray-800 text-base font-bold'>{formatRupiah(Number(paymentData?.total_amount) || 0)}</div>
										<div
                      id='click_copy_total_payment'
											className='pl-1'
											onClick={() => {
												console.log('copy total amount');
												copyToClipboard(String(paymentData?.total_amount));
											}}>
											<CopyIcon />
										</div>
									</div>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-300' />
								<div className='flex justify-between items-center mt-8 gap-2'>
									<button
                    id='click_btn_back_to_home'
										className={`bg-white text-rose-violet w-full font-semibold text-sm p-3 items-center border-1 border-solid border-rose-violet`}
										onClick={() => history.push('/')}>
										Back to home
									</button>
									<button
                    id='click_btn_already_paid'
										disabled={!paymentData || paymentData?.payment_status === 'EXPIRED' || status === 'EXPIRED'}
										className={`${
											!paymentData || paymentData?.payment_status === 'EXPIRED' || status === 'EXPIRED'
												? 'bg-gray-300 text-white border-gray-300'
												: 'bg-rose-violet text-white border-rose-violet'
										} w-full font-semibold text-sm p-3 items-center border-1 border-solid`}
										onClick={() => {
											console.log('click already paid');
											handleGetPaymentStatus(true);
										}}>
										Already Paid
									</button>
								</div>
							</div>
							<div className='mt-8'>
								<div className='text-gray-800 text-base font-bold'>How to make a payment:</div>
								<div className='ml-4'>
									{paymentData?.payment_instructions?.map((instruction, index) => {
										const isOpen = openIndex === index;
										return (
											<div key={`instruction_${index}`}>
												<div className='flex justify-between items-center py-2 cursor-pointer' onClick={() => toggleOpen(index)}>
													<div className='text-gray-800 text-sm font-bold'>{instruction?.title ?? ''}</div>
													{isOpen ? <ChevronUpIcon className='h-4 w-4 text-gray-900' /> : <ChevronDownIcon className='h-4 w-4 text-gray-900' />}
												</div>
												{isOpen && (
													<div className='ml-2'>
														{instruction?.description.split('\n').map((line, idx) => (
															<p key={idx} className='text-gray-900 text-xs mb-1'>
																{line}
															</p>
														))}
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Payment;
