/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uniqueID } from "../../../utils/constants";
import { getBannerList } from "../../../store/reducers/banner";

const useBanner = () => {
  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const { data: banners, loading } = useSelector((state) => state.banner);

  useEffect(() => {
    dispatch(
      getBannerList({
        unquieid: uniqueID,
      })
    );
  }, []);

  return {
    banners: banners || [],
    loading,
  };
};

export default useBanner;
