import React, { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { formatRupiah } from '../../utils/helper';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

const TooltipInfo = (props) => {
	const { title = '', items = [], id = '', btn_id = '' } = props;
	const [isShow, setIsShow] = useState(false);

	const handleShow = () => setIsShow(!isShow);

	return (
		<>
			<InformationCircleIcon className='h-4 w-4 ml-1 text-gray-400' onClick={handleShow} id={id} />
			<BottomSheet open={isShow} onDismiss={handleShow} header={<p className='py-2 text-center text-base text-gray-900 font-semibold'>{title}</p>} className='z-50 relative'>
				<div className='p-4'>
					{items?.map((item, index) => {
						return (
							<div key={index} className={`flex flex-row items-start border-b-1 border-solid border-gray-200 py-4`}>
								{item?.total && (
									<div className='h-8 w-8 bg-rose-violet bg-opacity-20 flex justify-center items-center font-xs text-rose-violet font-semibold rounded-md'>{item?.total}x</div>
								)}
								<div className='flex justify-between items-start gap-2 pl-2 w-full'>
									<div className='w-full'>
										<p className='font-semibold text-sm text-gray-900'>{item?.name}</p>
										<p className='text-xs text-gray-500 font-thin pt-1'>{item?.desc}</p>
									</div>
									<p className='font-semibold text-sm text-gray-900'>{formatRupiah(item?.nominal || item?.price || 0)}</p>
								</div>
							</div>
						);
					})}
					<button
            id={btn_id}
						className='capitalize bg-ufdPurple border border-ufdPurple text-center hover:bg-ufdPurple/80 w-full text-white rounded-md py-3 font-semibold text-sm mt-8'
						onClick={handleShow}>
						got it
					</button>
				</div>
			</BottomSheet>
		</>
	);
};

export default TooltipInfo;
