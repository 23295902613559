import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import arrow from '../../../common/images/revamp/icon/arrow-black.svg';
import searchImg from '../../../common/images/revamp/icon/search.svg';
import useSearchHeader from './useSearchHeader';

const SeachHeader = (props) => {
	const { query, handleSearch, inputRef } = props;
	const { shadowHeader } = useSearchHeader();

	return (
		<div className={twMerge('px-4 py-3 sticky top-0 z-5 flex items-center justify-between gap-2.5 bg-white transition-all shadow-none', shadowHeader && 'shadow-[0px_0px_22px_0px_#0000001A]')}>
			<Link to='/' id='back_btn_search'>
				<img src={arrow} alt='arrow-left w-8 h-8 cursor-pointer' />
			</Link>
			<div className='flex-auto flex items-center rounded-full border border-solid border-grayD3 p-2.5 gap-3'>
				<img src={searchImg} alt='search' />
				<input
					id='click_search_name'
          ref={inputRef}
					placeholder='what food are you looking for'
					name='search'
					className='flex-auto w-full m-0 bg-transparent border-none outline-none'
					value={query}
					onChange={(event) => {
						handleSearch(event.target.value);
					}}
				/>
			</div>
		</div>
	);
};

export default SeachHeader;
