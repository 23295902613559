import React from 'react';
import location from '../../../../common/images/revamp/icon/location.svg';
import { MathRandom } from '../../../../utils/helper';
import { BottomSheet } from 'react-spring-bottom-sheet';
import food from '../../../../common/images/food.png';
import { ClockIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';

const OutletByLocation = (props) => {
  const history = useHistory();

	const { isOpen, onClose, outlet } = props;

	const handleAction = (item) => {
		if (item && item.storeOpen == '1') {
			history.push('/outlet/' + item.storeSlug);
		}
	};

	return (
		<BottomSheet
			open={isOpen}
			onDismiss={onClose}
			snapPoints={({ maxHeight }) => [
				// snap top (90% height)
				maxHeight - maxHeight / 10,
				// snap bottom
				// maxHeight / 4,
				// snap middle
				// maxHeight * 0.6,
			]}
			header={<p className='m-0 py-2 text-left text-lg text-black37 font-semibold'>List of Outlets</p>}
			// className="relative pointer-events-auto z-[1000]"
		>
			<div className='px-4'>
				{!outlet &&
					new Array(6).fill('').map((item, index) => (
						<div key={index} className='flex justify-between gap-3 py-[14px] border-b border-solid border-b-grayD last:border-b-0'>
							<div className='rounded-lg w-6 h-6 bg-grayD3 animate-pulse' />
							<div className='grow space-y-1'>
								<div className='h-[21px] rounded-sm bg-grayD3 animate-pulse' style={{ width: `${MathRandom(120, 240)}px` }} />
								<div className='h-[21px] rounded-sm bg-grayD3 animate-pulse' style={{ width: `${MathRandom(60, 80)}px` }} />
								<div className='h-[21px] rounded-sm bg-grayD3 animate-pulse max-w-[calc(100vw-68px)]' style={{ width: `${MathRandom(200, 300)}px` }}></div>
							</div>
						</div>
					))}
				{outlet &&
					outlet.map((item, index) => (
						<div key={index} className='flex justify-between gap-3 py-[14px] border-b border-solid border-b-grayD last:border-b-0' onClick={() => handleAction(item)} style={{ cursor: item?.storeOpen === '1' && 'pointer' }}>
							<img src={item?.storeImage || food} alt='outlet-img' className='rounded-lg w-6 h-6' />
							<div className='grow space-y-1'>
								<div className={`${item?.storeOpen === '0' ? 'flex' : 'hidden'} items-center`}>
									<ClockIcon class='h-4 w-4 text-red-500 mr-1' />
									<div className='text-xs font-normal text-gray-900'>Closed {item?.outletOpenTime && `- Open at ${item?.outletOpenTime} AM Tommorow`}</div>
								</div>
								<p className='mb-0 font-semibold text-black37 line-clamp-1 max-w-[calc(100vw-68px)]'>{item?.storeName}</p>
								<div className='flex items-center gap-1'>
									<img src={location} alt='location' />
									<span>{item?.distance ? parseFloat(item?.distance).toFixed('2') : parseFloat(0).toFixed('2')} km</span>
								</div>
								<p className='mb-0 text-gray90 line-clamp-1 max-w-[calc(100vw-68px)]'>{item?.storeAddress}</p>
							</div>
						</div>
					))}
			</div>
		</BottomSheet>
	);
};

export default OutletByLocation;
