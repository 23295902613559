import React from "react";
import { Link } from "react-router-dom";

function OrderTabs({
  orderType,
  all = 0,
  waitingPayment = 0,
  onprogress = 0,
  delivering = 0,
  completed = 0,
  canceled = 0,
}) {
  return (
    <div className="pp-tags history-tags px-4 mb-0">
      <ul className="scrollbar-hide">
        <li className={orderType === "all" ? "active" : ""}>
          <Link to="/history/all" id="click_btn_history_all">All {all ? `(${all})` : ""}</Link>
        </li>
        {/* <li className={orderType === "waiting-payment" ? "active" : ""}>
          <Link to="/history/waiting-payment">
            Waiting Payment {waitingPayment ? `(${waitingPayment})` : ""}
          </Link>
        </li> */}
        {/* <li className={orderType === "on-process" ? "active" : ""}>
          <Link to="/history/on-process">
            On Progress {onprogress ? `(${onprogress})` : ""}
          </Link>
        </li> */}
        <li className={orderType === "delivering" ? "active" : ""}>
          <Link to="/history/delivering" id="click_btn_history_progress">
            On Progress {delivering ? `(${delivering})` : ""}
          </Link>
        </li>
        <li className={orderType === "completed" ? "active" : ""}>
          <Link to="/history/completed" id="click_btn_history_completed">
            Completed {completed ? `(${completed})` : ""}
          </Link>
        </li>
        <li className={orderType === "canceled" ? "active" : ""}>
          <Link to="/history/canceled" id="click_btn_history_cancel">
            Canceled {canceled ? `(${canceled})` : ""}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default OrderTabs;
